.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f3f3f3;
}

header {
  width: auto;
}

main {
  flex: 1;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;
}

footer {
  padding: 12px;
  text-align: center;
  background: #ccc;
  border-top: 1px solid #ccc;
  display: none;
}

.text-field {
  margin: 8px 0 8px 0 !important;
}
.document-editor .ck-editor__editable {
  min-height: 200px;
  border: 1px solid #c4c4c4 !important;
}

table th {
  font-weight: bold !important;
}
